import React, { useEffect, useState } from "react";

import { ChevronLeft } from "@material-ui/icons";
import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import dayjs, { Dayjs } from "dayjs";
import { navigate } from "gatsby";
import { decamelizeKeys } from "humps";
import { Input, Label } from "reactstrap";
import Swal from "sweetalert2";

import availabilityService from "~/utils/api/v1/availabilityService";
import appointmentAPI, { rescheduleAppointment } from "~/utils/api/v2/appointment";
import { NewTimeBlock, ParsedDate } from "~/utils/interfaces/Timeblock";

import "dayjs/locale/es";

dayjs.locale("es");

type NormalRescheduleProps = {
  id: string;
  className?: string;
};

function NormalReschedule({ id, className }: NormalRescheduleProps) {
  const [stateLoading, setStateLoading] = useState<boolean>(false);
  const [availabilities, setAvailabilities] = useState<Record<string, NewTimeBlock[]> | undefined>(undefined);
  const [noAvailability, setNoAvailability] = useState<boolean>(false);
  const [fetchingExtraAvailability, setFetchingExtraAvailability] = useState<boolean>(false);
  const [selectedDay, setSelectedDay] = useState<string>("");
  const [selectedTimeslot, setSelectedTimeslot] = useState<NewTimeBlock>();
  const [selectedParsedDate, setSelectedParsedDate] = useState<Dayjs | undefined>(undefined);
  const [weeksLoading, setWeeksLoading] = useState<string[]>([]);
  const [weeksLoaded, setWeeksLoaded] = useState<string[]>([]);
  const [currentWeekDisplay, setCurrentWeekDisplay] = useState<string>(dayjs().format("YYYY-MM-DD"));
  const [currentStartWeekDay, setCurrentStartWeekDay] = useState<Dayjs | undefined>();
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);

  const MIN_DATE = dayjs().format("YYYY-MM-DD");
  const MAX_DATE = dayjs().add(1, "months").format("YYYY-MM-DD");

  const { data: appointmentData, isLoading: isAppointmentLoading } = useQuery({
    queryKey: ["appointment", id],
    queryFn: async () => {
      const res = await appointmentAPI.get(id);
      return res.data;
    },
    enabled: !!id,
  });

  const { data: appointmentPatientsData, isLoading: isAppointmentPatientsLoading } = useQuery({
    queryKey: ["appointmentPatients", id],
    queryFn: async () => {
      const res = await appointmentAPI.appointmentPatients(id);
      const packItemIds = res.data.flatMap((obj) =>
        obj.appointment_patient_item_packs.map((appointmentPatientPack) =>
          appointmentPatientPack.pack.items.map((item) => item),
        ),
      );
      const itemIds = res.data.flatMap((obj) => obj.appointment_patient_items.map((item) => item.item.id));

      const ids = [...new Set([...packItemIds, ...itemIds])];

      const isFonasaItemValues = res.data.map((appointmentPatient) =>
        appointmentPatient.appointment_patient_items.map((item) => item.is_fonasa),
      );
      const isFonasaPackValues = res.data.map((appointmentPatient) =>
        appointmentPatient.appointment_patient_item_packs.map(
          (appointmentPatientPack) => appointmentPatientPack.is_fonasa,
        ),
      );

      const isFonasa = [...new Set(...isFonasaItemValues, ...isFonasaPackValues)].some((isFonasa) => isFonasa);

      return { itemIds: ids, isFonasa: isFonasa } as { itemIds: string[]; isFonasa: boolean };
    },
  });

  const isMinDateInCurrentWeek = React.useMemo(() => {
    const startDayNextWeek = currentStartWeekDay?.clone().add(-1, "week").format("YYYY-MM-DD");
    return startDayNextWeek && startDayNextWeek < MIN_DATE;
  }, [currentStartWeekDay]);

  const isMaxDateInCurrentWeek = React.useMemo(() => {
    const startDayNextWeek = currentStartWeekDay?.clone().add(1, "week").format("YYYY-MM-DD");
    return startDayNextWeek && startDayNextWeek > MAX_DATE;
  }, [currentStartWeekDay]);

  function moveToPrevWeek() {
    setCurrentStartWeekDay((prevValue) => {
      if (typeof prevValue === "undefined") return prevValue;
      return prevValue?.clone().add(-1, "week");
    });
  }

  function moveToNextWeek() {
    setCurrentStartWeekDay((prevValue) => {
      if (typeof prevValue === "undefined") return prevValue;
      changeNewWeekToDisplay(prevValue.clone().add(1, "week").format("YYYY-MM-DD"));
      return prevValue.clone().add(1, "week");
    });
  }

  function changeNewWeekToDisplay(week: string) {
    setCurrentWeekDisplay(week);
    const isWeekLoading = !!weeksLoading?.includes(week);
    setStateLoading(isWeekLoading);

    // prefetch next week availability
    const weekToCalculateAvailability = dayjs(week).clone().add(1, "week").format("YYYY-MM-DD");
    const isValidDate = weekToCalculateAvailability < MAX_DATE;

    const isNextWeekLoading = weeksLoading?.includes(weekToCalculateAvailability);
    const isNextWeekAlreadyInAvailability = weeksLoaded.includes(weekToCalculateAvailability);
    if (!isNextWeekLoading && isValidDate && !isNextWeekAlreadyInAvailability) {
      fetchAndProcessAvailabilities(weekToCalculateAvailability);
    }
  }

  const daysToShow = React.useMemo(() => {
    if (!currentStartWeekDay) return [];
    const visibleCalendarDaysAmount = 6;
    const visibleCalendarDaysArray = [];
    for (let index = 0; index <= visibleCalendarDaysAmount; index++) {
      visibleCalendarDaysArray.push(currentStartWeekDay.clone().add(index, "day"));
    }
    return visibleCalendarDaysArray;
  }, [currentStartWeekDay]);

  async function fetchAndProcessAvailabilities(fromDate: string) {
    setWeeksLoading((prev) => [...(prev ?? []), fromDate]);
    const retrocompatibleTimeblocks = await availabilityService.fetchAvailabilities({
      appointmentId: id,
      itemIds: appointmentPatientsData.itemIds,
      isFonasa: appointmentPatientsData?.isFonasa,
      includeSourceOnly: appointmentData.sales_source !== "marketplace",
      fromDate,
    });
    if (retrocompatibleTimeblocks && Object.keys(retrocompatibleTimeblocks).length > 0) {
      // Merge availability search results
      setAvailabilities((prevAvailability) => ({
        ...prevAvailability,
        ...retrocompatibleTimeblocks,
      }));
    }
    setWeeksLoading((prev) => prev?.filter((date) => date !== fromDate) ?? []);
    setWeeksLoaded((prev) => [...(prev ?? []), fromDate]);
    return retrocompatibleTimeblocks && Object.keys(retrocompatibleTimeblocks).length > 0;
  }

  async function calculateForwardAvailability(fromDate: string) {
    /**
     * Fetch 2 first weeks from now, second week after the first week fetch ends,
     * so that the nearest week is always shown first
     */
    await fetchAndProcessAvailabilities(fromDate);
    const firstDateOfSecondFetch = dayjs(fromDate).add(1, "week").format("YYYY-MM-DD");
    await fetchAndProcessAvailabilities(firstDateOfSecondFetch);
  }

  /**
   * Search for availability in other weeks because there's no availability after the first fetches at the beginning
   */
  async function fetchExtraAvailability(stopAt?: string) {
    setFetchingExtraAvailability(true);
    const maxVisibleWeeksAmount = 4;
    let firstDayOfWeek = dayjs();
    for (let index = 0; index <= maxVisibleWeeksAmount; index++) {
      const stringFirstDayOfWeek = firstDayOfWeek.format("YYYY-MM-DD");
      const validStopAtDate = !stopAt || stringFirstDayOfWeek <= stopAt;
      if (!validStopAtDate) break;
      if (weeksLoaded.includes(stringFirstDayOfWeek) || weeksLoading.includes(stringFirstDayOfWeek)) {
        firstDayOfWeek = firstDayOfWeek.add(1, "week");
        continue;
      }
      const availability = await fetchAndProcessAvailabilities(stringFirstDayOfWeek);
      if (availability && !stopAt) break;
      firstDayOfWeek = firstDayOfWeek.add(1, "week");
    }
    if ((!availabilities || Object.keys(availabilities).length === 0) && !stopAt) {
      setStateLoading(false);
      setNoAvailability(true);
    }
  }

  const handleReschedule = async () => {
    setIsButtonLoading(true);
    const formattedDate = dayjs(selectedParsedDate?.format("YYYY-MM-DD")).format("YYYY-MM-DD");
    const formattedStartHour = selectedTimeslot?.hour.slice(0, 5);
    const formattedEndHour = selectedTimeslot?.end_hour;
    const res = await rescheduleAppointment({
      appointmentId: id,
      data: decamelizeKeys({
        beginDate: `${formattedDate} ${formattedStartHour}`,
        workPeriodMaxLateness: `${formattedDate} ${formattedEndHour}`,
        skipRetentionCheck: true,
      }),
    });

    if (res.error) {
      Swal.fire({
        title: "Error",
        text: <div>Hubo un error al reagendar la cita: {res.error.error}</div>,
        icon: "error",
      });
    } else {
      Swal.fire({
        title: "Cita reagendada",
        text: "La cita se ha reagendado correctamente",
        icon: "success",
      });
      navigate(`/appointment/${id}`);
    }
    setIsButtonLoading(false);
  };

  /**
   * If there is no availability after the first load at the beginning,
   * start checking for availability in other weeks
   */
  useEffect(() => {
    if (
      !fetchingExtraAvailability &&
      weeksLoading.length === 0 &&
      weeksLoaded.length > 1 &&
      !availabilities &&
      !noAvailability
    ) {
      fetchExtraAvailability();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weeksLoaded]);

  useEffect(() => {
    const isWeekLoading = weeksLoading?.includes(currentWeekDisplay);
    setStateLoading(!!isWeekLoading);
  }, [weeksLoading, currentWeekDisplay]);

  useEffect(() => {
    if (availabilities && Object.keys(availabilities).length > 0) {
      const dateKeys = Object.keys(availabilities).sort();
      // Parse date keys into displayable formats
      const parsedDateKeys: ParsedDate[] = dateKeys.map((item) => {
        const date = dayjs(item, "YYYY-MM-DD");
        return {
          key: item,
          date: date,
          dayLabel: date.format("dddd"),
          month: date.format("MMMM"),
          dayNum: date.get("date"),
        };
      });

      const firstTimeslot = Object.values(availabilities)[0][0];
      setSelectedTimeslot({
        hour: firstTimeslot["hour"],
        end_hour: firstTimeslot["end_hour"],
      });
      if (!selectedParsedDate) {
        setSelectedParsedDate(parsedDateKeys[0].date);
        setSelectedDay(parsedDateKeys[0].key);
      }
    }
  }, [availabilities]);

  /**
   * Get current appointment and  availability
   */
  useEffect(() => {
    const today = dayjs().format("YYYY-MM-DD");
    setCurrentStartWeekDay(dayjs());
    if (appointmentPatientsData && appointmentData) {
      calculateForwardAvailability(today);
    }
  }, [appointmentPatientsData, appointmentData]);

  const isLoading = stateLoading || isAppointmentLoading || isAppointmentPatientsLoading;

  return (
    <div className={className}>
      <div className="bg-white rounded border border-black p-4">
        {isLoading && (
          <div className="animate-pulse w-full">
            <div className="h-4 bg-gray-200 rounded w-1/4 mb-2"></div>
            <div className="h-8 bg-gray-200 rounded w-3/4 mb-4"></div>
            <div className="h-4 bg-gray-200 rounded w-1/2 mb-2"></div>
            <div className="h-4 bg-gray-200 rounded w-1/3 mb-2"></div>
            <div className="h-4 bg-gray-200 rounded w-2/3 mb-2"></div>
          </div>
        )}
        {!isLoading && !noAvailability && (
          <>
            <div>
              <h3>Fecha y hora</h3>
              <div className="flex items-center justify-center gap-x-3 pb-2 pt-1">
                <p className="font-bold mb-0">
                  Semana del {daysToShow[0]?.format("DD [de] MMMM")} al {daysToShow.at(-1)?.format("DD [de] MMMM")}
                </p>
                <div className="flex gap-x-2">
                  <button
                    disabled={!!isMinDateInCurrentWeek}
                    onClick={moveToPrevWeek}
                  >
                    <ChevronLeft
                      className={clsx(
                        "h-10 w-10 fill-white rounded bg-[#049be5] hover:bg-[#049be5]/70 transition",
                        !!isMinDateInCurrentWeek && "bg-[#d4d4d4] pointer-events-none",
                      )}
                    />
                  </button>
                  <button
                    disabled={!!isMaxDateInCurrentWeek}
                    onClick={moveToNextWeek}
                  >
                    <ChevronLeft
                      className={clsx(
                        "h-10 w-10 rotate-180 fill-white bg-[#049be5] hover:bg-[#049be5]/70 rounded transition",
                        !!isMaxDateInCurrentWeek && "bg-[#d4d4d4] pointer-events-none",
                      )}
                    />
                  </button>
                </div>
              </div>
              <div className="overflow-x-scroll whitespace-nowrap pb-5 w-full flex justify-start xl:justify-center mt-3">
                {daysToShow?.map((day, i) => {
                  const hasAvailability =
                    availabilities && Object.keys(availabilities).includes(day.format("YYYY-MM-DD"));
                  return (
                    <button
                      key={i}
                      className={clsx(
                        "rounded-full inline-block px-5 py-2.5 text-center mr-2.5 bg-gray-200",
                        day.format("YYYY-MM-DD") === selectedDay && "bg-[#049BE5] text-white",
                        !hasAvailability && "pointer-events-none cursor-not-allowed text-gray-300",
                      )}
                      onClick={() => {
                        setSelectedDay(day.format("YYYY-MM-DD"));
                        setSelectedParsedDate(day);
                        const timestamp = availabilities[day.format("YYYY-MM-DD")][0];
                        const parsedTimeslot = {
                          hour: timestamp["hour"],
                          end_hour: timestamp["end_hour"],
                        };
                        setSelectedTimeslot(parsedTimeslot);
                      }}
                    >
                      <p className={clsx("text-xs capitalize mb-0", !hasAvailability && "text-gray-300")}>
                        {day.format("dddd").slice(0, 3)}
                      </p>
                      <p className={clsx("font-bold text-base capitalize mb-0", !hasAvailability && "text-gray-300")}>
                        {day.format("MMMM").slice(0, 3)}&nbsp;{day.get("date")}
                      </p>
                    </button>
                  );
                })}
              </div>
            </div>
            {selectedParsedDate && (
              <div className="mt-0 bg-gray-200 py-2.5 px-5">
                <p className="mb-0 text-sm font-bold text-[rgb(85,54,54)]">
                  <span style={{ textTransform: "capitalize" }}>{selectedParsedDate.format("dddd")}</span>,{" "}
                  {selectedParsedDate.date()} de{" "}
                  <span style={{ textTransform: "capitalize" }}>{selectedParsedDate.format("MMMM")}</span>
                </p>
              </div>
            )}
            <div>
              {availabilities &&
                Object.keys(availabilities).includes(selectedDay) &&
                availabilities[`${selectedDay}`].map((item, i) => {
                  return (
                    <button
                      key={i}
                      className="text-sm sm:text-base w-full border border-gray-200"
                    >
                      <Label className="w-full p-2.5 flex items-center hover:cursor-pointer" check>
                        <Input
                          type="radio"
                          name="radio1"
                          checked={selectedTimeslot?.hour === item.hour}
                          onChange={() => {
                            const parsedTimeslot = {
                              hour: item["hour"],
                              end_hour: item["end_hour"],
                            };
                            setSelectedTimeslot(parsedTimeslot);
                          }}
                        />
                        <p className="my-2 ml-2.5 text-lg">{`${item["hour"].slice(0, -3)} hrs - ${item["end_hour"]} hrs`}</p>
                      </Label>
                    </button>
                  );
                })}
            </div>
            <div
              className="flex justify-end"
              onClick={handleReschedule}
            >
              <button
                className="rounded bg-blue-500 hover:bg-blue-400 text-white px-4 py-2 mt-4"
                disabled={isButtonLoading}
              >
                {!isButtonLoading && "Reservar"}
                {isButtonLoading && (
                  <div className="loader border-t-2 border-white rounded-full w-5 h-5 animate-spin"></div>
                )}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default NormalReschedule;
