import React, { useState } from "react";

import clsx from "clsx";
import { navigate } from "gatsby-link";

import NormalReschedule from "~/components/Appointments/Reschedule/NormalReschedule";
import SuperadminReschedule from "~/components/Appointments/Reschedule/SuperadminReschedule";
import PrivateRoute from "~/components/Authentication/PrivateRoute";

interface RescheduleProps {
  id: string;
}

function Reschedule({ id }: RescheduleProps) {
  const [scheduleType, setScheduleType] = useState<"normal" | "superadmin" | "">("");

  const scheduleTypes = [
    { type: "normal", label: "Normal" },
    { type: "superadmin", label: "Superadmin" },
  ];
  return (
    <PrivateRoute>
      <div className="py-20 sm:py-10">
        <div className="mx-auto max-w-screen-xl flex px-5 items-center justify-center">
          {!scheduleType && (
            <div className="text-center mb-8">
              <h2 className="text-2xl leading-7 mt-5 mb-10 sm:text-xl sm:leading-4 sm:mt-2.5 sm:mb-5 font-bold">Tipo de agendamiento</h2>
              <div className="flex justify-center space-x-6">
                {scheduleTypes.map(({ type, label }) => (
                  <button
                    key={type}
                    className={clsx(
                      "px-6 py-3 text-lg font-medium rounded-md transition-all duration-300",
                      "shadow-lg hover:shadow-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 hover:bg-blue-200 border-blue-400 border-2",
                    )}
                    onClick={() => setScheduleType(type as "normal" | "superadmin")}
                  >
                    {label}
                  </button>
                ))}
              </div>
            </div>
          )}
          {scheduleType === "superadmin" && (
            <SuperadminReschedule
              id={id}
              onBack={async () => navigate(`/appointment/${id}`)}
            />
          )}
          {scheduleType === "normal" && <NormalReschedule id={id} className="w-full"/>}
        </div>
      </div>
    </PrivateRoute>
  );
}

export default Reschedule;
